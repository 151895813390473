import React from 'react';
import styled from 'styled-components';
import device from '../responsive/Device';
import Result from './Result';
import { Button, Jumbotron, Card, ListGroup, Carousel, Container, Row, Col, Form, Modal, Image } from 'react-bootstrap';
import moment from 'moment'
// import { BiMicrophone, BiMicrophoneOff, BiVideoOff } from 'react-icons/bi';
// import { BsCameraVideo } from 'react-icons/bs';
// import { RiCloseLine } from 'react-icons/ri'
import { FaRegHandPointLeft } from 'react-icons/fa'
import { AiTwotoneStop } from 'react-icons/ai'
import './index.css'
//import AdSense from 'react-adsense';
const image = require('../components/image/ad.jpg')
const Video = require('twilio-video');
const errorImage = require('../components/image/404.jpg')
const logo = require('../components/image/logo.png')
const mock = require('../components/image/mock4.png')

const bmo = require('../components/image/bmo.jpg')
const car = require('../components/image/car.jpg')
const car2 = require('../components/image/car2.jpg')

const implant = require('../components/image/implant.jpg')
const mcd = require('../components/image/mcd.jpg')
const michelin = require('../components/image/michelin.jpg')
const sub = require('../components/image/sub.jpg')
const { connect, createLocalTracks } = require('twilio-video');

const WeatherWrapper = styled.div`
  // max-width: 1500px;
  margin: 0 auto;
  height: calc(100vh - 64px);
  width: 100%;
  position: relative;
`;

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      weatherInfo: null,
      error: false,
      buildingName: 'Demo Condominiums',
      //prodUrl: 'https://testaquarius.mycondolink.net',
      //prodUrl: 'http://localhost:9000',
      prodUrl: 'http://localhost:3050',
      announcements: [],
      name: "DC Resident",
      showForm: false,
      videoShow: true,
      audioShow: true,
      showAvatar: false
    };
    this.handleTrackDisabled = this.handleTrackDisabled.bind(this)
    this.handleTrackEnabled = this.handleTrackEnabled.bind(this)
  }

  handleInputChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  showForm = async () => {
    this.setState({
      showForm: true
    })
  }

  async componentDidMount() {
    await this.setState({
      authenticated: true
    })
    fetch(`${this.state.prodUrl}/api/v1/check-ip`, {
      method: 'GET'
    }).then(message => message.json())
      .then(res => {
        this.setState({
          authenticated: res.authenticated
        })
        console.log(this.state.authenticated)
      });

    fetch(`${this.state.prodUrl}/api/v1/get-announcements-video/${this.state.buildingName}`, {
      method: 'GET'
    }).then(message => message.json())
      .then(res => {
        this.setState({
          announcements: res
        })
      });

    fetch(`${this.state.prodUrl}/api/v1/weather-data`, {
      method: 'GET'
    }).then(message => message.json())
      .then(async (res) => {
        await this.setState({
          weather: res
        })
        console.log(this.state.weather)
      });
    const { value } = 'Toronto'
    const APIkey = '491bc25f0c4150ab6e4366d28d54daf3'

    const weather = `https://api.openweathermap.org/data/2.5/weather?q=Toronto&APPID=${APIkey}&units=metric`;
    const forecast = `https://api.openweathermap.org/data/2.5/forecast/daily?q=Toronto&APPID=${APIkey}&units=metric`;

    Promise.all([fetch(weather), fetch(forecast)])
      .then(([res1, res2]) => {
        if (res1.ok && res2.ok) {
          return Promise.all([res1.json(), res2.json()]);
        }
        throw Error(res1.statusText, res2.statusText);
      })
      .then(([data1, data2]) => {
        const date = moment().format('LLL')
        const sunset = new Date(data1.sys.sunset * 1000).toLocaleTimeString().slice(0, 5);
        const sunrise = new Date(data1.sys.sunrise * 1000).toLocaleTimeString().slice(0, 5);
        console.log(data2)
        const weatherInfo = {
          buildingName: this.state.buildingName,
          city: data1.name,
          country: data1.sys.country,
          date,
          description: data1.weather[0].description,
          main: data1.weather[0].main,
          temp: data1.main.temp,
          highestTemp: data1.main.temp_max,
          lowestTemp: data1.main.temp_min,
          sunrise,
          sunset,
          clouds: data1.clouds.all,
          humidity: data1.main.humidity,
          wind: data1.wind.speed,
          forecast: data2.list,
        };
        this.setState({
          weatherInfo,
          error: false,
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          error: true,
          weatherInfo: null,
        });
      });
    setInterval(() => {
      this.setState({
        curTime: moment().format('lll')
      })
    }, 1000)
    console.log(moment(1602156232))
  }

  endCallPrematurely = async () => {
    if (this.state.room !== undefined) {
      fetch(`${this.state.prodUrl}/api/v1/mark-as-ended-by-caller/${this.state.room.sid}/${this.state.name}`, {
        method: 'POST'
      }).then(message => message.json())
        .then(res => {
          console.log(res)
          if (res.ended) {
            window.location.reload()
          }
        })
    }
    else {
      window.location.reload()
    }
  }
  setUpVideoCall = async () => {
    await this.setState({
      playMusic: true
    })
    document.getElementById("audio").play()
    fetch(`${this.state.prodUrl}/api/v1/video-request/${this.state.name}/${this.state.buildingName}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _buildingId: 4
      })
    }).then(message => message.json())
      .then(res => {
        console.log(res)
        console.log(res.token)
        /**
 * 
 * deviceId: "b5dba090f8eb8552f5cd512127b036916208beecfb0783bf44e89fef84a714a4"
groupId: "c082addeb5e12efaa33b2632abe5f6ef5f87f964abe020d553c8ff53b46ac840"
kind: "videoinput"
label: "USB Camera (0c45:636b)"
 */
        // navigator.mediaDevices.enumerateDevices().then(devices => {
        //   //const videoInput = devices.find(device => device.kind === 'videoinput');
        //   console.log(devices)
        // })
        // navigator.mediaDevices.enumerateDevices().then(devices => {
        //   const videoInput = devices.find(device => device.deviceId === 'b5dba090f8eb8552f5cd512127b036916208beecfb0783bf44e89fef84a714a4');
        //   return createLocalTracks({ audio: true, video: { deviceId: videoInput.deviceId } });
        // }).then(localTracks => {
        //   return connect('my-token', { name: 'my-room-name', tracks: localTracks });
        // }).then(room => {
        //   console.log('Connected to room ' + room.name);
        // });
        //b5dba090f8eb8552f5cd512127b036916208beecfb0783bf44e89fef84a714a4
        //const deviceId = 'b5dba090f8eb8552f5cd512127b036916208beecfb0783bf44e89fef84a714a4'
        Video.createLocalTracks({
          audio: true,
          video: {
            //deviceId: deviceId,
            width: 1000,
            height: 800
          }
        }).then(localTracks => {
          console.log(localTracks)
          return Video.connect(res.token, {
            name: `${this.state.name}`,
            tracks: localTracks
          });
        }).then(room => {
          this.setState({
            room: room
          })

          console.log(`Connected to Room: ${room.name}`);
          console.log(this.state.room);
          room.on('participantConnected', participant => {
            this.setState({
              showVideoButtons: true,
              playMusic: false
            })
            console.log(`Participant "${participant.identity}" connected`);
            setTimeout(() => {
              console.log("called")
              fetch(`${this.state.prodUrl}/api/v1/get-concierge/${this.state.room.sid}`, {
                method: 'GET'
              }).then(message => message.json())
                .then(res => {
                  this.setState({
                    cfirstname: res.firstname,
                    clastname: res.lastname
                  })
                })
                .catch((err) => {
                  console.log(err)
                })
            }, 5000)

            participant.tracks.forEach(publication => {
              if (publication.isSubscribed) {
                const track = publication.track;
                document.getElementById('remote-media-div').appendChild(track.attach());
              }
            });

            participant.on('trackSubscribed', track => {
              document.getElementById('remote-media-div').appendChild(track.attach());
            });
          });
        });
      });

  }
  endVideoMessage = async () => {
    await this.setState({
      videoEndModal: true
    })
  }

  handleVideoEndFinal = async () => {
    this.state.room.on('disconnected', room => {
      // Detach the local media elements
      console.log(this.state.room)
      room.localParticipant.tracks.forEach(publication => {
        const attachedElements = publication.track.detach();
        attachedElements.forEach(element => element.remove());
      });
    });
    this.state.room.disconnect();
    await this.setState({
      videoEndModal: false,
      modalOpen: false,
      showVideoButtons: false,
      showForm: true
    })
    window.location.reload()
  }

  handleVideoEnd = async () => {
    await this.setState({
      videoEndModal: false
    })
  }

  closeAudio = async () => {
    await this.setState({
      audioShow: !this.state.audioShow
    })
    if (!this.state.audioShow) {
      this.state.room.localParticipant.audioTracks.forEach(publication => {
        publication.track.disable();
      });
    }
    if (this.state.audioShow) {
      this.state.room.localParticipant.audioTracks.forEach(publication => {
        publication.track.enable();
      });
    }
  }
  closeVideo = async () => {
    await this.setState({
      videoShow: !this.state.videoShow
    })
    if (!this.state.videoShow) {
      this.state.room.localParticipant.videoTracks.forEach(publication => {
        publication.track.disable();
      });
    }
    if (this.state.videoShow) {
      this.state.room.localParticipant.videoTracks.forEach(publication => {
        publication.track.enable();
      });
    }
  }

  extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  async handleTrackDisabled(track) {
    await track.on('disabled', () => {
      this.setState({
        showAvatar: true
      })
      document.getElementById("audio").play()
    });
  }

  async handleTrackEnabled(track) {
    await track.on('enabled', () => {
      this.setState({
        showAvatar: false
      })
    });
  }

  render() {
    const { weatherInfo } = this.state;
    if (this.state.room !== undefined) {
      this.state.room.participants.forEach(participant => {
        participant.tracks.forEach(publication => {
          if (publication.isSubscribed) {
            this.handleTrackDisabled(publication.track);
          }
          publication.on('subscribed', this.handleTrackDisabled);
        });
      });
    }
    if (this.state.room !== undefined) {
      this.state.room.participants.forEach(participant => {
        participant.tracks.forEach(async (publication) => {
          if (publication.isSubscribed) {
            await this.handleTrackEnabled(publication.track);
          }
          publication.on('subscribed', this.handleTrackEnabled);
        });
      });
    }
    if (this.state.room !== undefined) {
      this.state.room.on('participantDisconnected', () => {
        console.log(this.state.room)
        if (this.state.room.participants.size === 0) {
           console.log('Room completed');
          this.endCallPrematurely()
          // fetch(`${this.state.prodUrl}/api/v1/get-transfer-status/${this.state.room.sid}`, {
          //   method: 'GET'
          // }).then(message => message.json())
          //   .then(res => {
          //     console.log(res.inTransfer)
          //     if (!res.inTransfer) {
          //       this.endCallPrematurely()
          //     }
          //     else {
          //       //do nothing
          //       const list = document.getElementById("remote-media-div");
          //       while (list.hasChildNodes()) {
          //         list.removeChild(list.firstChild);
          //       }
          //       this.setState({
          //         showAvatar: true
          //       })
          //       console.log(this.state.room)
          //     }
          //   })
        }
      });
    }
    if (this.state.room !== undefined) {
      this.state.room.on('participantConnected', () => {
        this.setState({
          showAvatar: false
        })
      })
    }
    console.log(this.state.room)
    // navigator.mediaDevices.enumerateDevices().then(devices => {
    //   console.log(devices)
    // })
    return (
      <>
        {(this.state.authenticated) ?
          <WeatherWrapper>
            <Container style={{ maxHeight: '100vh' }} fluid>
              <Row>

                <Col className="top-row" style={{ borderRight: '2px solid white' }}
                  xs={12} sm={12} md={12} lg={12} xl={(this.state.showForm) ? 4 : 6}>
                  {(!this.state.showForm) ?
                    (weatherInfo !== undefined && weatherInfo !== null) ?
                      <Result weather={weatherInfo}>

                      </Result>
                      :
                      null

                    :
                    null
                  }


                  {(!this.state.showForm) ?
                    <Col style={{}} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h4 style={{
                        color: 'white', paddingLeft: '30px', paddingRight: '30px',
                        borderTop: '1px solid white', borderBottom: '1px solid white',
                        paddingTop: '5px', paddingBottom: '5px'
                      }}>
                        Notices
                        <span style={{ float: 'right' }}>
                          {this.state.curTime}
                        </span>
                      </h4>
                      <Carousel controls={false} style={{ height: '300px' }} touch={true} >
                        {(this.state.announcements.length > 0) ?
                          this.state.announcements.slice(0, 5).map((announcement, key) => {
                            return (
                              <Carousel.Item style={{ height: '100%' }} interval={1000}>
                                <Card style={(!this.state.showForm) ? {
                                  background: 'transparent', height: '43vh', border: 'none', color: 'white', fontWeight: '900',
                                  paddingLeft: '10px', paddingRight: '10px'
                                } : {
                                  background: 'transparent', height: '43vh', fontSize: '12px', border: 'none', color: 'white', fontWeight: '900',
                                  paddingLeft: '10px', paddingRight: '10px'
                                }}>
                                  <Card.Body>
                                    <Card.Title>{announcement.Announcement.title}</Card.Title>
                                    <Card.Text>
                                      {/* {this.extractContent(announcement.Announcement.announcementDetail)} */}
                                      <p dangerouslySetInnerHTML={{ __html: (announcement.Announcement.announcementDetail) }} ></p>
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </Carousel.Item>
                            )
                          })
                          :
                          null
                        }
                      </Carousel>
                    </Col>
                    :
                    null
                  }

                  {(this.state.showForm) ?
                    <div style={{ marginTop: '50px', height: '68vh' }}>
                      <p style={{ textAlign: 'center', color: 'white', fontWeight: '600', fontSize: '40px', marginTop: '70px' }}>
                        {this.state.buildingName}
                        {/* Lakeside Place Condominiums */}
                      </p>
                      <p style={{ textAlign: 'center', color: 'white', fontWeight: '600', fontSize: '40px' }}>
                        Virtual Concierge
                      </p>
                      <p style={{ textAlign: 'center', marginTop: '70px' }}>

                        {(this.state.showVideoButtons) ?
                          <Button style={{
                            height: '160px', width: '300px', fontSize: '30px', textAlign: 'center', color: 'white',
                            backgroundColor: '#847F8E', border: 'none', marginLeft: '20px'
                          }} variant="outline-primary"
                            onClick={() => {
                              //this.setState({ showForm: false })
                              this.endVideoMessage()
                            }}>
                            End Call
                            <p>
                              <AiTwotoneStop style={{ color: 'red' }} />&nbsp;
                              <FaRegHandPointLeft />
                            </p>
                          </Button>
                          :
                          null
                        }
                      </p>
                    </div>
                    :
                    null
                  }

                  <Col className='ad-left' xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row>
                      <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                        <Row>

                        </Row>
                      </Col>
                      <Carousel indicators={false} controls={false} style={{ height: '300px' }} touch={true}>
                        {/* <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={netflix} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={foodbank} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={covid} />
                          </Col>
                        </Carousel.Item> */}
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={bmo} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={michelin} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={implant} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={mcd} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={sub} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={car} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={car2} />
                          </Col>
                        </Carousel.Item>
                      </Carousel>
                    </Row>
                  </Col>
                </Col>

                <Col style={{ height: '100vh' }} xs={12} sm={12} md={12} lg={12} xl={(this.state.showForm) ? 8 : 6}>
                  {(!this.state.showForm) ?
                    <div style={{ marginTop: '200px' }}>
                      <p style={{ textAlign: 'center', color: 'white', fontWeight: '600', fontSize: '40px', marginTop: '70px' }}>
                        {this.state.buildingName}
                      </p>
                      <p style={{ textAlign: 'center', color: 'white', fontWeight: '600', fontSize: '40px' }}>
                        Virtual Concierge
                      </p>
                      <p style={{ textAlign: 'center', marginTop: '70px' }}>

                        {(moment().isBetween(moment('00:00:00', "hh:mm:ss"), moment('23:59:59', "hh:mm:ss"))) ?
                          <Button style={{
                            height: '160px', fontSize: '30px', width: '300px', textAlign: 'center', color: 'white', backgroundColor: '#505A62',
                            border: 'none'
                          }}
                            onClick={() => this.showForm()}>
                            Click Here For Concierge
                            <p>
                              <AiTwotoneStop style={{ color: 'red' }} />&nbsp;
                              <FaRegHandPointLeft />
                            </p>
                          </Button>
                          :
                          null
                        }
                      </p>
                      <Row style={{ marginTop: '120px' }}>
                        <Col xs={6} sm={6} md={6} lg={6}>
                          <div>

                          </div>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                          <img style={{ float: 'right' }} height={'300px'} width={'450px'} src={logo} />
                        </Col>
                      </Row>
                    </div>
                    :
                    null
                  }
                  <Row style={{ marginTop: '30px' }}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div className="container" style={(this.state.showAvatar) ? { display: 'none' } : {}} id="remote-media-div">
                      </div>
                      {
                        (this.state.showAvatar) ?
                          <div>
                            <h4 style={{ textAlign: 'center', color: 'white' }}>Your call has been placed on hold. Please wait for the operator</h4>
                            <div className="player-buttons" style={{ justifyContent: 'center' }}>
                              <audio loop id="audio" autoplay>
                                <source src={'https://mycondolink-training-videos.s3.ca-central-1.amazonaws.com/music-2.mp3'} type="audio/mpeg" />
                                Your browser does not support the audio element
                              </audio>
                              <img style={{}} height={'300px'} width={'450px'} src={mock} />
                            </div>
                          </div>
                          :
                          null
                      }
                    </Col>
                  </Row>

                  {(this.state.showVideoButtons) ?
                    <Row >
                      <Col style={{}} xs={12} sm={12} md={12} lg={12}>
                        <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                          <div style={{ height: '12vh', backgroundColor: 'white', paddingTop: '10px' }}>
                            <p style={{ paddingLeft: '50px', marginTop: '20px' }}>
                              <span style={{ color: 'gray' }}>Concierge Name:</span> {this.state.cfirstname} {this.state.clastname}
                              <img style={{ float: 'right', marginTop: '-50px' }} height={'150px'} width={'250px'} src={logo} />
                            </p>
                            <p style={{ paddingLeft: '50px' }}>
                              <span style={{ color: 'gray' }}>Status:</span>  Connected &nbsp;<AiTwotoneStop style={{ color: 'green' }} />
                            </p>
                          </div>
                        </div>
                      </Col>

                    </Row>
                    :
                    null
                  }

                  {(this.state.showForm && !this.state.showVideoButtons) ?
                    <Form style={{ marginTop: '500px' }}>
                      <Form.Group controlId="formBasicEmail">
                        <div style={{ marginTop: '50px', textAlign: 'center' }}>
                          <Button style={{ height: '70px', width: '200px', textAlign: 'center', color: 'white', backgroundColor: '#847F8E', border: 'none' }}
                            onClick={() => this.setUpVideoCall()} variant="outline-primary">
                            Call Concierge
                          </Button>
                          <Button style={{ height: '70px', width: '200px', textAlign: 'center', color: 'white', backgroundColor: '#847F8E', border: 'none', marginLeft: '20px' }} variant="outline-primary"
                            onClick={() => {
                              this.setState({ showForm: false })
                              this.endCallPrematurely()
                            }}>Close
                          </Button>
                        </div>
                      </Form.Group>
                      {(this.state.playMusic) ?
                        <div>
                          <h2 style={{ marginTop: '50px', fontWeight: 'bold', textAlign: 'center', color: 'white', fontSize: '40px' }}>Connecting....Please wait</h2>
                          <audio loop id="audio" autoplay>
                            <source src={'https://mycondolink-training-videos.s3.ca-central-1.amazonaws.com/music-2.mp3'} type="audio/mpeg" />
                            Your browser does not support the audio element
                          </audio>
                        </div>
                        :
                        null
                      }
                    </Form>
                    :
                    null
                  }
                  <Col className='ad-right' style={{ marginTop: '50px' }} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row>
                      <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                        <Row>

                        </Row>
                      </Col>
                      <Carousel indicators={false} controls={false} style={{ height: '300px' }} touch={true} style={{}}>
                        {/* <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={netflix} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px', objectFit: 'cover' }} alt="ad image" src={foodbank} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px', objectFit: 'cover' }} alt="ad image" src={covid} />
                          </Col>
                        </Carousel.Item> */}
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={bmo} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={michelin} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={implant} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={mcd} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={sub} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={car} />
                          </Col>
                        </Carousel.Item>
                        <Carousel.Item style={{ height: '100%' }} interval={1000}>
                          <Col xs={11} sm={11} md={10} lg={11} xl={11}>
                            <img style={{ width: '100%', height: '25vh', paddingRight: '5px', paddingLeft: '80px' }} alt="ad image" src={car2} />
                          </Col>
                        </Carousel.Item>
                      </Carousel>
                    </Row>
                  </Col>
                </Col>

              </Row>
            </Container>
            <Modal show={this.state.videoEndModal} onHide={this.handleVideoEnd}>
              <Modal.Header closeButton>
                <Modal.Title>End Video Call</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure you want to end this video call?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleVideoEnd}>
                  No
                </Button>
                <Button variant="primary" onClick={this.handleVideoEndFinal}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
          </WeatherWrapper>
          :
          <Image src={errorImage} />
        }
      </>
    );
  }
}

export default App;
